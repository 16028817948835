<template>
  <div class="flex space-x-3">
    <div
      data-cy="activity-card"
      class="rounded-full h-10 w-10 flex items-center justify-center"
      :class="backgroundClass"
    >
      <slot name="icon" />
    </div>
    <div class="bg-white flex flex-col rounded-md shadow-main grow">
      <div v-if="title" class="py-3 px-5 bg-grey-hover">
        <p class="text-headline text-xs font-semibold">
          {{ title }}
        </p>
      </div>
      <slot name="content" />
      <div class="flex justify-between items-center p-4 shadow-sm space-x-4">
        <slot name="footer" />
        <log-comments-toggle v-model="showComments" :count="comments.length" />
      </div>
      <log-comments-section v-if="showComments" :comments="comments" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import LogCommentsToggle from "@/components/comunicationLogs/comments/LogCommentsToggle.vue";
import LogCommentsSection from "@/components/comunicationLogs/comments/LogCommentsSection.vue";

import type { CommunicationLogComment } from "@/models/communicationLogs";

defineProps<{
  title?: string;
  backgroundClass?: string;
  comments: CommunicationLogComment[];
}>();

const showComments = ref(false);
</script>
